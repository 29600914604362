<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.new_m", {
                m: $t("message.anecdote"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.performance_type") }}
                </span>
                <el-form-item
                    prop="performance_type"
                    class="label_mini">
                        <el-select
                          v-model="form.performance_type"
                          placeholder="Ishlash turi"
                          class="w-100"
                          :size="'medium'"
                          filterable
                          clearable
                          popper-class="my-data-khan"
                        >
                          <el-option label="To'lovli" value="1"></el-option>
                          <el-option label="To'lovsiz" value="0"></el-option>
                        </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.staff") }}
                </span>
                <el-form-item
                    prop="staff_id"
                    class="label_mini">
                        <select-staff
                          v-model="form.staff_id"
                          :placeholder="$t('message.staff')"
                          :size="'medium'"
                          filterable
                          clearable
                          popper-class="my-data-khan"
                        >
                        </select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t('message.reason') }}
                </span>
                  <el-form-item
                      prop="staff_id"
                      class="label_mini">
                      <el-select
                      v-model="form.additional_work_reason_id"
                      :placeholder="$t('message.reason')"
                      class="w-100"
                      :size="'medium'"
                      popper-class="my-data-khan"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="(reason, index) in reasons"
                        :key="'guestreason-' + index"
                        :label="reason.name"
                        :value="reason.id"
                      ></el-option>
                    </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.work_time") }}
                </span>
                <el-form-item
                    prop="work_time"
                    class="label_mini">
                    <el-time-picker
                      v-model="form.work_time"
                      :size="'medium'"
                        is-range
                        range-separator="-"
                        start-placeholder="Start time"
                        end-placeholder="End time"
                        value-format="HH:mm:ss"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.comment") }}
                </span>
                <el-form-item
                    prop="comment"
                    class="label_mini">
                    <el-input
                        type="textarea"
                        :rows="5"
                        :placeholder="$t('message.comment')"
                        :inputValue="form.comment"
                        v-model="form.comment"
                        class="text-area-el"
                        :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  name: "ReasonController",
  data() {
    return {};
  },
  components: {
  },
  mounted() {
    this.reasonsAction();
  },
  computed: {
    ...mapGetters({
      rules: "additionalWork/rules",
      reasons:"additionalWork/reasons",
      columns: "additionalWork/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "additionalWork/store",
      reasonsAction: "additionalWork/reasons",
    }),
    // saveWorks(){
    //      this.saveWorkAll(this.form)
    //       .then((res) => {
    //         this.$alert(res);
    //         this.updateList(getItem("userId"));
    //         let route = this.$router.push({
    //           name: "additionalWork",
    //           params: {
    //               id: getItem("userId")
    //           },
    //         });
    //     window.open(route.href, "_self");
    //       })
    //       .catch((err) => {
    //         this.$alert(err);
    //       });
    // },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        console.log(this.form);
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
              this.form =[];
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>

